/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .content-toolbar-container {
  width: 100%;
  margin-bottom: 20px;
}
.altai-theme-admin .content-toolbar {
  top: 0;
  color: #fff;
  width: 100%;
  height: 50px;
  background-color: #0a264e;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0);
  position: absolute;
  display: flex;
  top: 29px;
  z-index: 70;
  justify-content: space-between;
  border-bottom: 4px solid #0068ff;
}
.altai-theme-admin .content-toolbar .altai-admin-icon,
.altai-theme-admin .content-toolbar .icon {
  outline: none;
  border-radius: 50%;
  border: 2px solid transparent;
  height: 20px;
  width: 20px;
  background-color: transparent;
  color: #ffffff;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  display: inline-block;
}
.altai-theme-admin .content-toolbar .altai-admin-icon:hover,
.altai-theme-admin .content-toolbar .icon:hover {
  border: 2px solid #008cff;
  color: #008cff;
}
.altai-theme-admin .content-toolbar a.md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-raised[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-fab[disabled],
.altai-theme-admin .content-toolbar .md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .content-toolbar .md-button.md-raised[disabled],
.altai-theme-admin .content-toolbar .md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .content-toolbar .md-button.md-fab[disabled] {
  background-color: #585858;
  color: #b9b9b9 !important;
}
.altai-theme-admin .toolbar-languages {
  padding-right: 0px;
}
.altai-theme-admin .content-toolbar-items {
  color: #fff;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 5px 10px 0 10px;
}
.altai-theme-admin .content-toolbar-items ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 50px;
}
.altai-theme-admin .content-toolbar-items ul li {
  padding-left: 20px;
  padding-right: 20px;
  display: table-cell;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #EBEBEB;
  color: #fff;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  vertical-align: middle;
  height: 50px;
}
.altai-theme-admin .content-toolbar-items ul li i {
  color: #0068ff;
  padding-right: 5px;
}
.altai-theme-admin .content-toolbar-items ul li.disabled {
  cursor: default;
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items ul li.disabled i {
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items ul li:hover {
  background-color: #F5F5F5;
  color: black;
}
.altai-theme-admin .content-toolbar-items ul li.disabled:hover {
  background-color: #EBEBEB;
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items .toolbar-items-vertical-center {
  padding-top: 0px;
  top: -8px;
  position: relative;
}
.altai-theme-admin .content-toolbar-items li.separator {
  cursor: default;
  padding-left: 0;
  padding-right: 0;
}
.altai-theme-admin .content-toolbar-items li.separator:hover {
  background-color: #EBEBEB;
}
.altai-theme-admin .content-toolbar-items .separator-lines {
  width: 2px;
  box-shadow: #F9F9F9 -1px 0 inset;
  height: 50px;
  background-color: #c9c9c9;
}
.altai-theme-admin .right {
  float: right;
}
.altai-theme-admin .left {
  float: left;
}
.altai-theme-admin .center {
  margin: auto;
}
